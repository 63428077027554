import { cva, VariantProps } from "cva"
import { STOPPER_COLORS } from "shared/constants"

const base = [
  "flex",
  "items-center",
  "min-w-max",
  "py-[4.5px]",
  "pl-[6px]",
  "pr-[8px]",
  "rounded-md",
  "font-bold",
  "text-[10px]/[11px]",
  "text-primary-on-color"
]

const stopper = cva(base, {
  variants: {
    color: {
      [STOPPER_COLORS.BLUE]: "bg-gradient-stopper-buyers-choice",
      [STOPPER_COLORS.GREEN]: "bg-gradient-stopper-powerful-battery",
      [STOPPER_COLORS.YELLOW]: "bg-gradient-stopper-good-camera",
      [STOPPER_COLORS.VIOLET]: "bg-gradient-stopper-fast-delivery",
      [STOPPER_COLORS.RED]: "bg-bg-red-error",
      [STOPPER_COLORS.LIGHT_BLUE]: "bg-main-blue-1000",
      [STOPPER_COLORS.DEEP_BLUE]: "bg-bg-blue-accent",
    }
  }
})

export type StopperProps = VariantProps<typeof stopper>

export default {
  stopper
}
